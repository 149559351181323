import {clearCacheExceptCustomer, isNotNull} from "../Utils";
var mqtt = require('mqtt');


var mqttConf = {
    host: "mqtt.rd.atosworldline.com",
    port: 8003,
    username: "rd",
    password: "@t0s@t0s",
    rejectUnauthorized: false,
    protocol: 'wss'
}

var demoId = localStorage.getItem("DKC_demoId");
var topicENROLL;
var topicENROLLEND;
var topicPLATEDETECTED;
var topicSTARTFUEL;
var topicENDFUEL;
var topicProductScanned;
var topicReadyToEnroll;
var topicLoyaltyUpdate;
var topicEndDemo;
var topicReset;
var client;
var initOk = false;

console.log("MQTT LOADED")

function initTopics(){
    demoId = localStorage.getItem("DKC_demoId");
    if(isNotNull(demoId)){

        topicENROLL = "wldd/demokit/dks/fuel/" + demoId + "/enroll";
        topicENROLLEND = "wldd/demokit/dks/fuel/" + demoId + "/enrollEnd";
        topicPLATEDETECTED = "wldd/demokit/dks/fuel/" + demoId + "/plateDetected";
        topicSTARTFUEL = "wldd/demokit/dks/fuel/" + demoId + "/startFuel";
        topicENDFUEL = "wldd/demokit/dks/fuel/" + demoId + "/endFuel";
        topicProductScanned = "wldd/demokit/dks/fuel/" + demoId + "/productScanned";
        topicReadyToEnroll = "wldd/demokit/dks/fuel/" + demoId + "/readyToEnroll";
        topicLoyaltyUpdate = "wldd/demokit/dks/fuel/" + demoId + "/loyaltyUpdate";
        topicEndDemo = "wldd/demokit/dks/fuel/" + demoId + "/demoEnd";
        topicReset = "wldd/demokit/dks/fuel/" + demoId + "/resetDemo";
        initOk = true;
    }
}

export function publishMessage(type, message = "", callback = null) {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        client.on('connect', function () {
            var topic = getTopic(type);
            if(isNotNull(callback)){
                client.publish(topic, message, callback);
            }else{
                client.publish(topic, message);
            }
        });
    }
}

export function subscribe(type, callback) {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        var topic = getTopic(type);

        client.subscribe(topic);

        client.on('message', function (topic, message) {
            callback(message.toString());
            client.end();
        })
    }
}

export function susbscribeReset(self) {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        client.subscribe(topicReset)

        client.on('message', function () {
            clearCacheExceptCustomer(self);
            client.end()
        })
    }
}

export function unsusbscribeReset() {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        client.unsubscribe(topicReset)
    }
}

function getTopic(type) {
    var topic;
    switch (type) {
        case "ENROLL":
            topic = topicENROLL;
            break;
        case "ENROLL_END":
            topic = topicENROLLEND;
            break;
        case "PLATE_DETECTED":
            topic = topicPLATEDETECTED;
            break;
        case "START_FUEL":
            topic = topicSTARTFUEL;
            break;
        case "END_FUEL":
            topic = topicENDFUEL;
            break;
        case "PRODUCT_SCANNED":
            topic = topicProductScanned;
            break;
        case "READY_TO_ENROLL":
            topic = topicReadyToEnroll;
            break;
        case "LOYALTY_UPDATE":
            topic = topicLoyaltyUpdate;
            break;
        case "END_DEMO":
            topic = topicEndDemo;
            break;
    }
    return topic;
}
