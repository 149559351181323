<template>
    <div id="ticket" class="fuel-body" ref="ticket">
        <div v-if="!$route.query.fromCoupon" class="fuel-ticket-header">
            <div class="transaction-details">
                <div class="transaction-details-header">
                    <span>{{ $d(date, 'long') }}</span>
                    <span>{{$t("authorisationNumber", {authNumber : authNumber})}}</span>
                </div>
                <div v-if="selectedPump != ''" class="transaction-details-body-fuel">
                    <img src="@/assets/img/picto_fuel_black.png" style="width: 50px; margin-top: 16px"/>
                    <span class="station-name">{{selectedPump.label}}</span>
                    <div class="transaction-details-body">
                        <div class="transaction-details-body-title">
                            <span>{{selectedFuel.label}}</span>
                            <span>{{$t("totalAmount")}}</span>
                        </div>
                        <div class="transaction-details-content">
                            <span>{{quantity}} L</span>
                            <span>{{formatPrice(totalAmount)}}</span>
                        </div>
                    </div>
                </div>
                <div v-else class="transaction-details-body-products">
                    <div v-for="cartProduct in ticket.products" :key="cartProduct.id" class="cardContainer">
                        <div class="productCard cardTemplateCartProduct row no-gutters">
                            <div v-if="cartProduct.qtyLocked" class="scannerTag">
                                <img src="@/assets/img/qrcode.png" alt="qrcode" class="qrcode_icon">
                            </div>
                            <div class="col-3 col-md-2 align-self-center" style="float:left;">
                                <img style="border:0" class="img-thumbnail imgProductCart" :src="img_url+cartProduct.product.image" />
                            </div>
                            <div class="product col-9 col-md-10 row no-gutters">
                                <div  v-if="getLang() == 'fr'">
                                    <div class="nameProductCart col-12 align-self-center no-gutters">{{cartProduct.product.nameFr}}</div>
                                    <div class="categoryProductCart col-12 align-self-center no-gutters">{{cartProduct.product.category.nameFr}} </div>
                                </div>
                                <div v-else>
                                    <div class="nameProductCart col-12 align-self-center no-gutters">{{cartProduct.product.nameEn}}</div>
                                    <div class="categoryProductCart col-12 align-self-center no-gutters">{{cartProduct.product.category.nameEn}} </div>
                                </div>
                                <div class="col-12 row align-self-end no-gutters">
                                    <div class="col-4 col-md-2 no-gutters">
                                        <p class="priceProduct">{{formatPrice(cartProduct.product.price.toFixed(2))}}</p>
                                    </div>
                                    <div class="col-6 col-md-7 row no-gutters ml-auto">
                                        <div class="linkToCart ml-auto">
                                            <span class="qtyProduct">x{{cartProduct.qty}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="fuel-ticket-content">
            <div v-if="!$route.query.fromCoupon && selectedPump == ''" class="fuel-ticket-content shop">
                <div class="totalAmount">
                    {{$t('totalAmount')}} : <strong v-if="nbrProducts>0">{{formatPrice(ticket.totalAmount.toFixed(2))}}</strong>
                    <strong v-else>{{formatPrice(0.00)}}</strong>
                </div>
            </div>
            <div v-else>
                <lottie class="fuel-ticket-check" :options="defaultOptions" v-on:animCreated="handleAnimation" />
                <h6>{{$t("finishedTransaction")}}</h6>
            </div>
            <span v-if="!$route.query.fromCoupon" style="color: #0066A1; font-style: italic;" v-html="this.$t('wonLoyaltyPoints', {loyaltyPoints : loyaltyPointsEarned, totalLoyaltyPoints : totalLoyaltyPointsEarned})"></span>
            <div class="fuel-ticket-loyalty">
                <button v-if="!$route.query.fromCoupon" class="btn valid-button" @click="$router.push($parent.base + '/coupons')">{{$t("loyaltyGiftsBtnLabel")}}</button>
                <button v-else class="btn valid-button" @click="$router.push($parent.base + '/')">{{$t("goHomePage")}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import Lottie from 'vue-lottie';
    var Utils = require("@/assets/js/Utils");
    var MQTT = require("@/assets/js/Fuel/MqttService");
    var Customer = require("@/assets/js/Customer");
    var Basket = require("@/assets/js/Fuel/Basket");
    var animationData = require('@/assets/img/check.json');
    var Stats = require("@/assets/js/Stats");

    export default {
        name: "Ticket",
        components: {
            Lottie
        },
        data() {
            return {
                img_url:"",
                selectedFuel: "",
                authNumber : parseInt(Math.random(Math.random())*100000),
                selectedPump: "",
                animationData: animationData,
                defaultOptions: {
                    animationData: animationData,
                    rendererSettings: {
                        clearCanvas: false
                    },
                    loop: false
                },
                animationSpeed: 1,
                quantity : 0,
                totalAmount : 0,
                ticket: JSON.parse(localStorage.getItem("DKC_fuel_basket")),
                loyaltyPointsEarned : 0,
                date : new Date()
            }
        },
        created() {
            var config = Utils.getConfig();
            this.img_url = config.url_img;
            var orderHistory = JSON.parse(localStorage.getItem("DKC_fuel_history")) || []
            if(!this.$route.query.noNeedParams && !this.$route.query.fromCoupon){
                this.selectedFuel = JSON.parse(sessionStorage.getItem("DKC_selectedFuel"));
                this.selectedPump = JSON.parse(sessionStorage.getItem("DKC_selectedPump"));
                this.quantity = localStorage.getItem('DKC_fuel_qty') || 0;
                this.totalAmount = localStorage.getItem('DKC_fuel_amount') || 0;
                if (this.totalAmount == 0 || this.quantity == 0) {
                    this.loyaltyPointsEarned = 0;
                    this.$router.push(this.$parent.base + "/");
                }else{
                    this.loyaltyPointsEarned = 4;
                    orderHistory.unshift({
                        type : "fuel",
                        date: this.date,
                        orderContent:{
                            fuelType : this.selectedFuel,
                            quantity : this.quantity,
                            amount : parseFloat(this.totalAmount),
                            earnedLoyaltyPoints : this.loyaltyPointsEarned,
                            totalLoyaltyPoints : this.totalLoyaltyPointsEarned
                        },
                        authNumber : this.authNumber
                    })
                    localStorage.setItem("DKC_fuel_history", JSON.stringify(orderHistory));
                }
                localStorage.removeItem('DKC_fuel_qty');
                localStorage.removeItem('DKC_fuel_amount');
                
            }else if(this.ticket.products.length != 0 && !this.$route.query.fromCoupon){
                this.loyaltyPointsEarned = parseInt(this.ticket.totalAmount / 10) + 1;
                Basket.validBasket(res =>{
                    localStorage.setItem("DKC_fuel_basket", JSON.stringify(res));
                    this.$parent.basketChange();
                })
                orderHistory.unshift({
                    type : "shop",
                    date: this.date,
                    orderContent:{
                        products : this.ticket.products,
                        amount : parseFloat(this.ticket.totalAmount),
                        earnedLoyaltyPoints : this.loyaltyPointsEarned,
                        totalLoyaltyPoints : this.totalLoyaltyPointsEarned
                    },
                    authNumber : this.authNumber
                })
                localStorage.setItem("DKC_fuel_history", JSON.stringify(orderHistory));
            }else if(this.$route.query.fromCoupon){
                this.loyaltyPointsEarned = 0;
                orderHistory.unshift({
                    type : "coupon",
                    date: this.date,
                    orderContent:{
                        product : this.$t("coffee"),
                        amount : 0,
                        usedLoyaltyPoints : 12
                    },
                    authNumber : this.authNumber
                })
                localStorage.setItem("DKC_fuel_history", JSON.stringify(orderHistory));
            }else{
                this.$router.push(this.$parent.base + "/")
            }
            Customer.updateCustomerLoyaltyPoints(this.totalLoyaltyPointsEarned);
            MQTT.publishMessage('LOYALTY_UPDATE', JSON.stringify({LoyaltyPoints : this.totalLoyaltyPointsEarned}));
            localStorage.setItem("DKC_fuel_loyalty", this.totalLoyaltyPointsEarned);
        },
        mounted(){
            this.$refs.ticket.focus();
           
            if(this.$route.query.fromCoupon){
                Stats.setStats({ nbUsedCoupons : 1 })
            }else{
                Stats.setStats({ nbTicketAccess : 1 })
            }
            navigator.serviceWorker.getRegistration().then(reg =>{
                reg.getNotifications().then( notifications => notifications.forEach( n => n.close() ))
            })
        },
        methods: {
            handleAnimation: function (anim) {
                this.anim = anim;
            },
            getLang(){
                return this.$root.$i18n.locale;
            },
            formatPrice(price) {
                price = parseFloat(price);
                return Utils.formatPrice(price);
            }
        },
        computed:{
            nbrProducts() {
                var count = 0;
                if(this.ticket.products){
                    this.ticket.products.forEach(function(cartProduct) {
                        count+=cartProduct.qty;
                    });
                    return count
                }
            },
            totalLoyaltyPointsEarned(){
                return parseInt(this.loyaltyPointsEarned) + parseInt(localStorage.getItem('DKC_fuel_loyalty'))
            }
        }
    }
</script>

<style scoped>

</style>